<script>
export default {
  name: 'interview',
  data () {
    return {
      domain: null,
      options: {
        roomName: null,
        width: '100%',
        height: '100%',
        jwt: null,
        configOverwrite: {
          disableDeepLinking: true,
          fileRecordingsEnabled: false,
          liveStreamingEnabled: false,
          fileRecordingsServiceSharingEnabled: false,
          enableCalendarIntegration: false,
          defaultLanguage: 'pt-br'
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: [
            'microphone', 'camera', 'desktop', 'fullscreen',
            'fodeviceselection', 'hangup', 'profile', 'chat',
            'etherpad', 'settings',
            'videoquality', 'filmstrip', 'stats', 'shortcuts',
            'tileview', 'help', 'mute-everyone'
          ],
          SETTINGS_SECTIONS: ['devices'],
          SHOW_JITSI_WATERMARK: true,
          SHOW_WATERMARK_FOR_GUESTS: true,
          SHOW_BRAND_WATERMARK: true
        },
        parentNode: null
      }
    }
  },
  mounted () {
    this.$store.dispatch('attemptGetMeetDetails', this.$route.params.id)
      .then(({ data }) => {
        this.domain = data.domain
        this.options.jwt = data.token
        this.options.roomName = data.code
      })
      .finally(() => {
        this.$loadScript(`https://${this.domain}/external_api.js`)
          .then(() => {
            this.options.parentNode = document.querySelector('#meet')
            // eslint-disable-next-line no-undef
            window.enlizt_meet = new JitsiMeetExternalAPI(this.domain, this.options)
          })
          .finally(() => {
            // eslint-disable-next-line no-undef
            enlizt_meet.addEventListener('readyToClose', () => {
              this.$router.push({ name: 'individual.home' })
            })
          })
      })
  },
  destroyed () {
    // eslint-disable-next-line no-undef
    enlizt_meet.dispose()
    this.$unloadScript(`http://${this.domain}/external_api.js`)
  }
}
</script>
<template>
  <div id="meet"></div>
</template>
<style lang="scss">
#meet {
  position: absolute;
  top: 62px;
  left: 0;
  bottom: 0;
  z-index: 9;
  height: calc(100vh - 62px) !important;
  width: 100vw;
  overflow: hidden;
}
</style>
